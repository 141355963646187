import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Input from 'reusecore/src/elements/Input';
import Button from 'reusecore/src/elements/Button';
import Container from 'common/src/components/UI/Container';
import { navigate } from 'gatsby-link'
import NewsletterSectionWrapper, {
  NewsletterForm,
} from './newsletterSection.style';

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}


const NewsletterSection = ({ sectionHeader, sectionTitle, btnStyle }) => {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }
  return (
    <NewsletterSectionWrapper id="contact">
      <Container>
        <Box {...sectionHeader}>
          <Heading content="Contact Us" {...sectionTitle} />
        </Box>
        <Box>
          <NewsletterForm>
            <form style={{margin: '0 auto', width: 400}} name="contact" method="POST" data-netlify-honeypot="bot-field"  data-netlify="true" netlify action="/success">
            <input type="hidden" name="form-name" value="contact" />
            <input placeholder="Name" style={{padding: '10px 10px', width: '100%', border: '1px solid #1a73e8', color: '#1ya73e8'}} type="text" name="name" onChange={handleChange} />
            <br/><br/>
            <input placeholder="Email" style={{padding: '10px 10px', width: '100%', border: '1px solid #1a73e8', color: '#1ya73e8'}} type="email" name="email" onChange={handleChange} />
            <br/><br/>
            <textarea placeholder="Message" style={{padding: '10px 10px', width: '100%', border: '1px solid #1a73e8', color: '#1ya73e8'}} name="message" onChange={handleChange} />
            <br/><br/>
            <button style={{backgroundColor: '#1a73e8', color: 'white', padding: '15px 20px', width: '100%', border: 'none'}} type="submit">Send</button>
            </form>
          </NewsletterForm>
        </Box>
      </Container>
    </NewsletterSectionWrapper>
  );
};

// NewsletterSection style props
NewsletterSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
};

// NewsletterSection default style
NewsletterSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // button default style
  btnStyle: {
    minWidth: '152px',
    minHeight: '45px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default NewsletterSection;
